<template>
  <li
    v-if="!currentUploadImage"
    class="frame-item cursor-pointer"
    @click="isMobile && !isSpecialDeviceBrowser ? initImgUpload() : initImgUploadFile()"
  >
    <!-- ghost image -->
    <img
      :src="require(`@/assets/upload-guide/${frame}`)"
      :alt="title"
      class="ghost-image"
      :class="{ scalable: scalable }"
    />
    <!-- frame name -->
    <span class="frame-name font-weight-thin text-white" v-html="title"></span>
    <!-- add button -->
    <v-touch>
      <img :src="theme.otherPhotoUploadIcons" class="add-btn font-weight-thin" />
    </v-touch>
    <input
      :ref="frameKey"
      type="file"
      :id="frameKey"
      :name="frameKey"
      style="display: none"
      :multiple="false"
      @change="uploadPhoto"
      accept="image/*"
    />
  </li>
  <li v-else class="frame-item">
    <b-overlay
      :show="currentUploadImage.onUpload"
      no-wrap
      spinner-variant="success"
      :opacity="0.5"
    ></b-overlay>
    <img
      :src="currentUploadImage.url"
      width="100%"
      class="uploaded-image"
      :class="currentUploadImage.err ? 'img-upload-fail' : ''"
      :draggable="false"
    />
    <div v-if="currentUploadImage.err" class="upload-fail-text d-flex align-items-center">
      <img src="@/assets/images/icons/ic_upload_fail.svg" class="upload-fail-icon" alt="">
      <p class="font-weight-thin">Image upload failed</p>
    </div>
    <b-progress
      v-if="currentUploadImage.progress && currentUploadImage.progress !== 100"
      class="img-upload-progress"
      :value="currentUploadImage.progress"
      variant="success"
      striped
    ></b-progress>
    <div class="edit-options-list" v-show="!currentUploadImage.onUpload" v-if="!currentUploadImage.err">
      <v-touch v-on:tap="rotateImg">
        <custom-icon
          name="roll"
          class="edit-icon"
          width="100%"
          @click="rotateImg"
        />
      </v-touch>
      <v-touch v-on:tap="deleteImg">
        <custom-icon
          name="delete"
          class="edit-icon"
          width="100%"
          :m0="true"
          @click="deleteImg"
        />
      </v-touch>
    </div>
  </li>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "FrameItem",
  props: {
    frame: {
      type: String,
      default: () => "",
    },
    title: {
      type: String,
      default: () => "",
    },
    scalable: {
      type: Boolean,
      default: () => false,
    },
    frameKey: {
      type: String,
      default: () => "",
    },
    uploadImages: {
      type: Array,
      default: () => [],
    },
    isMobile: Boolean,
    isSpecialDeviceBrowser: Boolean,
  },
  computed: {
    ...mapState({
      theme: (state) => state.commonVariables.theme,
    }),
    currentUploadImage() {
      return this.uploadImages.find((item) => item.key === this.frameKey);
    },
  },
  methods: {
    initImgUploadFile() {
      this.$refs[this.frameKey].click();
    },
    initImgUpload() {
      this.$emit("initImgUpload");
      this.$store.dispatch('setKeyFrame', this.frameKey);
    },
    uploadPhoto: function (event) {
      var input = event.target;
      if (input.files.length > 0) {
        this.$emit("uploadPhoto", input.files[0], this.frameKey, this.title);
      }
    },
    deleteImg() {
      this.$emit(
        "deleteImg",
        this.currentUploadImage.filename,
        this.currentUploadImage.key
      );
    },
    rotateImg() {
      this.$emit("rotateImg", this.currentUploadImage);
    },
  },
  components: {
    CustomIcon: () => import("../common/CustomIcon"),
  },
};
</script>
<style lang="scss">
.frame-item {
  width: 100%;
  height: calc(30vw - 0.5rem);
  min-height: 109px;
  background-color: #5e5e5e;
  font-size: 10px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  .ghost-image {
    width: 45%;
    transform: translateY(-3px);
    &.scalable {
      width: 70%;
    }
  }
  .frame-name {
    position: absolute;
    bottom: 3px;
    right: 50%;
    transform: translateX(50%);
    width: 100%;
    .super {
      vertical-align: super;
      font-size: 6px;
    }
  }
  .add-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 18px;
    height: 18px;
    display: grid;
    place-items: center;
  }
  .uploaded-image {
    height: 100%;
    object-fit: cover;
  }
  .edit-options-list {
    position: absolute;
    bottom: 8px;
    left: 8px;
    right: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .edit-icon {
      width: 25px;
      height: 25px;
      cursor: pointer;
    }
  }
  .img-upload-fail {
    background-color: #eee;
  }
  .upload-fail-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    justify-content: center;
    .upload-fail-icon {
      width: 14px !important;
      height: 14px !important;
    }
    p {
      color: #D20319 !important;
      font-size: 11px !important;
      margin-left: 5px;
      margin-bottom: 0;
    }
  }
}
@media screen and(min-width: 768px) {
  .frame-item {
    min-width: 150px;
  }
}
@media screen and(min-width: 900px) {
  .frame-item {
    height: 100%;
    .edit-icon {
      width: 18px !important;
      height: 18px !important;
    }
    .uploaded-image {
      height: 109px;
    }
  }
}
</style>